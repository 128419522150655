import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { SectionInformationService } from '../../services/section-information/section-information.service';
import { map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SalePolicyService {
  companyID;
  infos;

  constructor(
    private db: AngularFirestore, private sectionInformation: SectionInformationService
  ) {
    this.infos = sectionInformation.getInfos();
    if (this.infos['infos_user'] !== undefined) {
      this.companyID = this.infos.infos_user.company;
    } else {
      this.sectionInformation.hasLoaded.subscribe(
        event => {
          this.companyID = this.infos.infos_user.company;
        }
      );
    }
  }

  getShippingCompany(companyId, cb) {
    this.db.collection(`companies/${companyId}/shipping_company`, ref => ref.orderBy('name', 'asc')).snapshotChanges().pipe(take(1), map(shippings => {
      return shippings.map( s => {
        const data = s.payload.doc.data();
        const id = s.payload.doc.id;
        return { data, id };
      });
    })).subscribe(shipping => {
      cb(null, shipping);
    });
  }

  // Lista todos os fretes
  listFreights(companyId, cb) {
    this.db.collection(`companies/${companyId}/freight`).snapshotChanges().pipe(take(1), map(freigths => {
      return freigths.map( f => {
        const data = f.payload.doc.data();
        const id = f.payload.doc.id;

        return { data, id };
      });
    })).subscribe(freigth => {
      cb(null, freigth);
    });
  }

  // Lista as restrições dos fretes
  listFreightsRestrictions(companyId, cb) {
    this.db.collection(`companies/${companyId}/freight_restriction`).snapshotChanges().pipe(take(1), map(freigths => {
      return freigths.map( f => {
        const data = f.payload.doc.data();
        const id = f.payload.doc.id;

        return { data, id };
      });
    })).subscribe(freigth => {
      cb(null, freigth);
    });
  }

  // Lista frete especifico
  listTableFreights(companyId, id, cb) {
    this.db.doc(`companies/${companyId}/freight/${id}`).valueChanges().pipe(take(1)).subscribe(item => {
      cb(item);
    });
  }

  listPriceTables(companyId, id, cb) {
    this.db.doc(`companies/${companyId}/price_tables/${id}`).valueChanges().pipe(take(1)).subscribe(item => {
      cb(item);
    });
  }

  getPolices(companyID) {
    return new Promise((resolve, reject) => {
      return this.db.doc(`companies/${companyID}/pricing_policy_settings/settings`).ref.get().then(result => {
        return resolve(result.data());
      }).catch((err) => {
        return reject(err);
      });
    });
  }

  getEcommercePolice(companyID) {
    return new Promise((resolve, reject) => {
      return this.db.doc(`companies/${companyID}/configs_app/ecommerce_module`).ref.get().then(result => {
        return resolve(result.data());
      }).catch((err) => {
        return reject(err);
      });
    });
  }

  getOrdersModule(companyID) {
    return new Promise((resolve, reject) => {
      return this.db.doc(`companies/${companyID}/configs_app/orders_module`).ref.get().then(result => {
        return resolve(result.data());
      }).catch((err) => {
        return reject(err);
      });
    });
  }
}
