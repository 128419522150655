import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { ApiService } from '../../services/api/api.service';
import { UserService } from '../../services/user/user.service';
import { LocalStorageService } from "../../services/local-storage/local-storage.service";
import { FirebaseApp } from '@angular/fire';
import { OrderService } from "../../services/order/order.service";
import { ToastrService } from "../../services/toastr/toastr-service.service";
import { IOrderStatusData } from "../../models/OrderStatus";
import { AuthService } from '../../services/auth/auth.service';
import { SalePolicyService } from '../../services/sale-policy/sale-policy.service';
import { CartService } from '../../services/carts/cart.service';
import * as numeral from 'numeral';
declare var $: any;

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.css']
})
export class OrderDetailsComponent implements OnInit {

  orderCode;

  user;
  order = {};
  finalStatus: IOrderStatusData;

  currentGrade = {
    description: 'Grade De Produtos',
    products: [],
  };
  client_selected = {};
  productsIds: any[] = [];
  quantity;
  client_cnpj = '';
  isLoggedIn: boolean;
  isento_st = false;
  ncm_list: any;
  ecommerceConfigs = {};
  urlBase = '';
  showQtdField: boolean;
  loggedOutPriceTable: String;
  defaultPriceTable: String;
  orderTypes = [];
  selectedOrderType = {};

  loadedData: boolean = false;

  // status: IOrderStatusData;
  status = [];
  statusIdAtual;
  statusIdAssements;
  show_review;
  showAssements: boolean = false;

  private sub: any;

  showCheckOrderReceived: boolean;

  constructor(
    private firebase: FirebaseApp,
    private apiService: ApiService,
    private route: ActivatedRoute,
    private userService: UserService,
    private router: Router,
    private localStorageService: LocalStorageService,
    private toastr: ToastrService,
    private authService: AuthService,
    private salePolicyService: SalePolicyService,
    private cartService: CartService,
  ) {
    this.isLoggedIn = this.authService.isLoggedIn();
    this.ecommerceConfigs['biaction_id'] = this.localStorageService.get('biaction_id');
    const url = this.router['location']._platformLocation.location.origin;
    this.urlBase = url.split('://')[1].split('.')[0];
  }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      const code = params['code'];

      if (code) {
        this.orderCode = code;
      }

      this.userService.getUserData().then((res) => {
        if (!res['error']) {
          this.user = res['user'];
        }

        this.getOrder();
      });
    });
  }

  getOrder() {
    this.apiService.getOrderOne(this.orderCode).subscribe((res) => {
      if (!res['error']) {
        this.order = res['order'];
        this.statusIdAtual =  this.order['status']['id']
      }

      this.getFinalStatus(this.order['status']['code'])
      this.formatOrder(this.order);

      this.loadedData = true;
    });
  }

  formatOrder(order) {
    this.order['products'].forEach(product => {
      product.image = product.images && product.images.length > 0 && typeof product.images[0] !== 'undefined' ?
        'https://p.biaction.com.br/' + this.user['client_id'] + '/products/300x300/' + product.images[0].filename :
        'https://app.pedidosdigitais.com.br/img/sem-imagem-mini.png';

    });

    this.order['created_at'] = moment(order.created_at).format('DD/MM/yyyy H:m');

    this.productsIds = this.order['products'].map((products) => products.product_id);
    this.quantity = this.order['products'].map((products) => products.quantity);
    this.getEcommerceConfigs()
  }

  reviewOrder() {
    this.router.navigate([`/orders/${this.order['code']}/review`]);
  }

  /**
   * Função auxiliar utilizada para fazer a troca de classes e dar movimento no icone
   * quando o panel é aberto.
   * @param id Id do item utilizado para identificar o botão
   */
  openPanelContent(id) {
    if ($("a[aria-expanded]").length == 0) {
      $(`.btn-${id}`).addClass('collapse-showing');
    } else {
      $("a[aria-expanded]").each(function() {
        // comparação com === porque o valor do atributo é string
        if ($('.btn-collapse').attr('aria-expanded') === 'true') {
          $(this).removeClass('collapse-showing');
        } else {
          $(this).addClass('collapse-showing');
        }
      })
    }
  }

  getFinalStatus(code) {
    this.apiService.getFinalStatus().subscribe(
      result => {
        if (result.error) {
          this.toastr.showError(result.message);
          return;
        }

        this.finalStatus = result.data;

        if (this.finalStatus && this.finalStatus.id == code) {
          this.showCheckOrderReceived = this.localStorageService.get('show_check_order_received');
        }
      },
      error => {
        console.error(error);
        this.toastr.showError("Ops! Ocorreu um erro ao carregar as informações do Pedido. Tente novamente.");
      }
    );
  }

  /* Configs products e-commerce */

  getEcommerceConfigs() {

    this.authService.getInfosByUrl('?url_base=' + this.urlBase);

    this.ecommerceConfigs = this.authService.setEcommerceConfigs();
    this.getEcommercePolicy();
  }

  getEcommercePolicy() {
    this.salePolicyService.getEcommercePolice(this.ecommerceConfigs['biaction_id']).then((res) => {
      this.showQtdField = res && res['show_qtd_field'] ? res['show_qtd_field'] : false;
      this.loggedOutPriceTable = res && res['logged_out_price_table'] ? res['logged_out_price_table'] : '';
      this.getPricingPolicy();
    });
  }

  getPricingPolicy() {
    this.salePolicyService.getPolices(this.ecommerceConfigs['biaction_id']).then((res) => {
      this.defaultPriceTable = res && res['default_price_table'] ? res['default_price_table'] : '';
      this.getUser();
    });
  }


  getUser() {
    this.userService.getUserData().then((res) => {
      if (!res['error']) {
        this.user = res['user'];

        this.statusIdAssements = this.user['client']['status_id_to_show_order_review'];
        this.show_review = this.user['client']['show_review_order_ecommerce'];

        if(this.statusIdAssements == this.statusIdAtual && this.show_review == '1' ){
          this.showAssements = true;
        }

        this.client_selected = this.user.store ? this.user.store : {};

        if (this.client_selected['doc_number'] && this.client_selected['doc_number'] &&
          this.client_selected['doc_number'] !== '' && this.client_selected['doc_number'].length > 0) {
          this.client_cnpj = this.client_selected['doc_number'].replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
            '\$1.\$2.\$3/\$4-\$5');
          if (this.client_selected['doc_number'].length === 11) {
            this.client_cnpj = this.client_selected['doc_number'].replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g,
              '\$1.\$2.\$3-\$4');
          }
        }

        if (!this.user.company) {
          this.toastr.showError('Cliente com grupo de cliente não configurado, por favor, atualize o cadastro e tente novamente.');
          setTimeout(() => {
            this.authService.authLogout();
            window.location.href = '/login';
            return;
          }, 4000);
        }
        this.getPriceTableById();
      }
    });
  }


  getPriceTableById() {
    this.apiService.getPriceTablesAll().subscribe((res) => {
      if (!res['error']) {
        const priceTables = res['price_tables'];

        if (this.user.store.default_price_table === null) {
          this.toastr.showError('Cliente sem tabela de preço padrão, por favor, atualize o cadastro e tente novamente.');
          return;
        }

        const storePriceTable = priceTables.find(
          (priceTable) => priceTable.id === this.user.store.default_price_table
        );

        this.user.store.default_price_table_code = storePriceTable.code;

        this.getTaxRules();
        this.getOrderTypes();
      }
    });
  }

  getTaxRules() {
    const store = this.user && this.user.store ? this.user.store : {};
    const tax_rules_id = store.tax_rules_id ? store.tax_rules_id : '';

    if (tax_rules_id !== '') {
      this.apiService.getTaxRules(tax_rules_id).subscribe((res) => {
        if (!res['error']) {
          const fiscalTaxRules = res['fiscalTaxRules'];
          if (fiscalTaxRules !== undefined) {
            this.isento_st = fiscalTaxRules.isento_st
              ? fiscalTaxRules.isento_st
              : false;
            this.ncm_list = fiscalTaxRules.ncm;
          }
        }
      });
    }
  }

  getOrderTypes() {
    const that = this;

    this.apiService
      .getOrderTypes(this.isLoggedIn, this.ecommerceConfigs['pd_id'])
      .subscribe((res) => {
        if (!res['error']) {
          res['order_types'].forEach((type) => {
            if (typeof type.data.ecommerce !== 'undefined' && type.data.ecommerce.active === true) {
              that.orderTypes.push({
                id: type.id,
                name: type.data.name,
                categories: type.data.ecommerce.categories,
              });

              that.selectedOrderType = {
                id: type.id,
                name: type.data.name,
                categories: type.data.ecommerce.categories,
              };

              this.localStorageService.set('selectedOrderTypeId', this.selectedOrderType['id']);

              this.getAllowedProductsToUser();
            }
          });
        }
      });
  }

  getAllowedProductsToUser() {

    const productsRequest = this.firebase
      .functions()
      .httpsCallable('products-getCart');

    const data = {
      ids: this.productsIds,
      price_table_id: this.user.store.default_price_table_code,
      source: 'new-ecommerce',
      company: {
        id: this.ecommerceConfigs['biaction_id'],
      },
      order_type_id: this.selectedOrderType['id'],
    };


    productsRequest(data)
      .then((res) => {
        if (res['data']['products']) {
          this.currentGrade.products = res['data']['products'].map((prod) => {
            prod['image'] = prod['photo_cover']
              ? 'https://p.biaction.com.br/' +
              this.ecommerceConfigs['pd_id'] +
              '/products/300x300/' +
              prod['photo_cover']
              : 'https://app.pedidosdigitais.com.br/img/sem-imagem-mini.png';
            prod['has_discount'] =
              prod['default_discount'] > 0 &&
              prod['price_no_discounts'] > prod['price_with_discounts']
                ? true
                : false;
            prod['price_default'] =
              prod['price_default'] && prod['price_default'] > 0
                ? prod['price_default']
                : 0;
            prod['price_no_discounts'] = prod['product_price_table'];
            prod['price_with_discounts'] = prod['product_price_table'] - (prod['product_price_table'] * prod['default_discount']) / 100;
            prod['total_discount_percentage'] = prod['has_discount']
              ? numeral(
                ((prod['price_with_discounts'] -
                    prod['price_no_discounts']) /
                  prod['price_no_discounts']) *
                100
              ).format('0')
              : 0;
            prod['stock'] =
              prod['stock_place_order_type'] > 0
                ? prod['stock_place_order_type']
                : 0;

            const ipi = prod.ipi;
            if (
              prod.ncm !== '' &&
              this.ncm_list !== undefined &&
              Object.keys(this.ncm_list).length > 0
            ) {
              const ncm = this.ncm_list[prod.ncm];
              if (ncm !== undefined) {
                const state = this.user.store.address_state;
                const ipi_ncm = ncm.ipi;
                const isento_ipi = ncm.isento_ipi ? ncm.isento_ipi : false;
                if (!isento_ipi) {
                  if (ipi_ncm && !isNaN(ipi_ncm)) {
                    prod['ipi'] = ipi_ncm;
                  } else {
                    prod['ipi'] = ipi !== -1 && ipi !== null ? ipi : 0;
                  }
                } else {
                  prod['ipi'] = 0;
                }
                if (!this.isento_st) {
                  if (prod.fromabroad) {
                    prod['st'] =
                      ncm.im !== undefined && ncm.im[state] !== undefined
                        ? ncm.im[state]
                        : 0;
                  } else {
                    prod['st'] =
                      ncm.st !== undefined && ncm.st[state] !== undefined
                        ? ncm.st[state]
                        : 0;
                  }
                } else {
                  prod['st'] = 0;
                }
                prod['tax_discount'] =
                  ncm.tax_discount !== undefined &&
                  ncm.tax_discount[state] !== undefined
                    ? ncm.tax_discount[state]
                    : 0;
              } else {
                prod['ipi'] = ipi !== -1 && ipi !== null ? ipi : 0;
                prod['st'] = 0;
                prod['tax_discount'] = 0;
              }
            } else {
              prod['ipi'] = ipi !== -1 && ipi !== null ? ipi : 0;
              prod['st'] = 0;
              prod['tax_discount'] = 0;
            }
            return prod;
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  addAllToCart() {
    const products = this.currentGrade.products;
    let i = 0;
    for (const product of products) {
      this.quantity[i]
      this.addToCart(product, this.quantity[i]);
      i++;
    }
    if(products.length > 0){
      this.toastr.showSuccess('Produto(s) inserido(s) ao carrinho com sucesso!');
    }else{
      this.toastr.showWarning('Nenhum produto encontrado')
    }
  }
  addToCart(product, qtd) {
    this.cartService.setProduct(product, qtd, false);
  }

}
