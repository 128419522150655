import {Component, OnInit} from '@angular/core';
import {ApiService} from '../../services/api/api.service';
import {UserService} from '../../services/user/user.service';
import * as numeral from 'numeral';
import {LocalStorageService} from '../../services/local-storage/local-storage.service';
import {ActivatedRoute, Router, NavigationEnd} from '@angular/router';
import {AuthService} from '../../services/auth/auth.service';
import {CartService} from '../../services/carts/cart.service';
import {ToastrService} from '../../services/toastr/toastr-service.service';
import {SalePolicyService} from '../../services/sale-policy/sale-policy.service';
import {FirebaseApp} from '@angular/fire';
import {filter, map} from 'rxjs/operators';
import {Title} from '@angular/platform-browser';

declare var $: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  inputs: ['slide-to'],
})
export class HomeComponent implements OnInit {
  user;

  products = [];
  banners = [];
  orderTypes = [];
  pageOfItems = [];

  selectedOrderType = {};

  loadedData: boolean = false;
  showQtdField = false;
  loggedOutPriceTable: String;
  isGrid = true;

  bannerImage = '';
  term = '';

  productSelected = {};

  private cachedData = {};

  paginatedData = [];
  page: number = 1;
  hitsPerPage: number = 20;
  nbHits: number;
  nbPages: number = 1;
  maxSize: number = 7;

  client_selected = {};
  client_cnpj = '';

  grades;

  msg = '';
  error = '';

  ncm_list: any;
  isento_st = false;

  basePath = `https://p.biaction.com.br/`;
  storeName = 'Ecommerce';
  urlBase = '';

  isLoggedIn: boolean;

  currentGrade = {
    description: 'Grade De Produtos',
    products: [],
  };

  defaultPriceTable: String;

  ecommerceConfigs = {};
  allCategories = [];

  constructor(
    private firebase: FirebaseApp,
    private apiService: ApiService,
    private userService: UserService,
    private localStorageService: LocalStorageService,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private cartService: CartService,
    private toastrService: ToastrService,
    private salePolicyService: SalePolicyService,
    private toastr: ToastrService,
  ) {
    this.ecommerceConfigs['biaction_id'] = this.localStorageService.get('biaction_id');
    this.term = this.route.snapshot.params['term'];
    this.isLoggedIn = this.authService.isLoggedIn();

    const url = this.router['location']._platformLocation.location.origin;
    // const url = 'https://bemfixa.sellentt.com.br/home';
    // const url = 'https://uzzyferramentas.sellentt.com.br/';
    // const url = 'https://suportescatarinense.sellentt.com.br/home';
    this.urlBase = url.split('://')[1].split('.')[0];
  }

  ngOnInit() {
    this.route.params.subscribe((val) => {
      this.term = this.route.snapshot.params['term'];
      this.getEcommerceConfigs();
      this.getGrades();
      this.getCategoriesWithChildren();
    });
  }

  async getEcommerceConfigs() {
    // Método Principal para pegar configurações da Empresa e salvar no Storage
    await this.authService.getInfosByUrl('?url_base=' + this.urlBase);

    // Método para setar as configurações do Ecommerce
    this.ecommerceConfigs = this.authService.setEcommerceConfigs();

    // Banners
    this.banners = this.ecommerceConfigs['banners'] !== '' ? this.ecommerceConfigs['banners'].split(',') : [];

    // // Verifica se o Usuário está LOGADO
    // if (this.isLoggedIn) {
    //   this.getUser();
    // } else {
    //   this.getAllProducts();
    // }

    // Método para buscar as configurações do Ecommerce
    this.getEcommercePolicy();
  }

  getEcommercePolicy() {
    this.salePolicyService.getEcommercePolice(this.ecommerceConfigs['biaction_id']).then((res) => {
      this.showQtdField = res && res['show_qtd_field'] ? res['show_qtd_field'] : false;
      this.loggedOutPriceTable = res && res['logged_out_price_table'] ? res['logged_out_price_table'] : '';
      this.getPricingPolicy();
    });
  }

  getCategoriesWithChildren() {
    const isUserLoggedIn = this.isLoggedIn;

    const getCategoryChildrenSubscription = this.apiService.getCategoryChildrenById(1, isUserLoggedIn, this.ecommerceConfigs['pd_id']);

    const handleResponse = (res) => {
      if (!res['error']) {
        this.allCategories = res['categories'];
      }
    };

    getCategoryChildrenSubscription.subscribe(handleResponse);
  }

  getPricingPolicy() {
    this.salePolicyService.getPolices(this.ecommerceConfigs['biaction_id']).then((res) => {
      this.defaultPriceTable = res && res['default_price_table'] ? res['default_price_table'] : '';

      // Verifica se o Usuário está LOGADO
      if (this.isLoggedIn) {
        this.getUser();
      } else {
        this.getAllProducts();
      }
    });
  }

  getUser() {
    this.userService.getUserData().then((res) => {
      if (!res['error']) {
        this.user = res['user'];
        this.client_selected = this.user.store ? this.user.store : {};

        if (this.client_selected['doc_number'] && this.client_selected['doc_number'] &&
          this.client_selected['doc_number'] !== '' && this.client_selected['doc_number'].length > 0) {
          this.client_cnpj = this.client_selected['doc_number'].replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
            '\$1.\$2.\$3/\$4-\$5');
          if (this.client_selected['doc_number'].length === 11) {
            this.client_cnpj = this.client_selected['doc_number'].replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g,
              '\$1.\$2.\$3-\$4');
          }
        }

        if (!this.user.company) {
          this.toastr.showError('Cliente com grupo de cliente não configurado, por favor, atualize o cadastro e tente novamente.');
          setTimeout(() => {
            this.authService.authLogout();
            window.location.href = '/login';
            return;
          }, 4000);
        }
        this.getPriceTableById();
      }
    });
  }

  getPriceTableById() {
    this.apiService.getPriceTablesAll().subscribe((res) => {
      if (!res['error']) {
        const priceTables = res['price_tables'];

        if (this.user.store.default_price_table === null) {
          this.toastr.showError('Cliente sem tabela de preço padrão, por favor, atualize o cadastro e tente novamente.');
          return;
        }

        const storePriceTable = priceTables.find(
          (priceTable) => priceTable.id === this.user.store.default_price_table
        );

        this.user.store.default_price_table_code = storePriceTable.code;

        this.getTaxRules();
        this.getOrderTypes();
      }
    });
  }

  getTaxRules() {
    const store = this.user && this.user.store ? this.user.store : {};
    const tax_rules_id = store.tax_rules_id ? store.tax_rules_id : '';

    if (tax_rules_id !== '') {
      this.apiService.getTaxRules(tax_rules_id).subscribe((res) => {
        if (!res['error']) {
          const fiscalTaxRules = res['fiscalTaxRules'];
          if (fiscalTaxRules !== undefined) {
            this.isento_st = fiscalTaxRules.isento_st
              ? fiscalTaxRules.isento_st
              : false;
            this.ncm_list = fiscalTaxRules.ncm;
          }
        }
      });
    }
  }

  getOrderTypes() {
    const that = this;

    this.apiService
      .getOrderTypes(this.isLoggedIn, this.ecommerceConfigs['pd_id'])
      .subscribe((res) => {
        if (!res['error']) {
          res['order_types'].forEach((type) => {
            if (typeof type.data.ecommerce !== 'undefined' && type.data.ecommerce.active === true) {
              that.orderTypes.push({
                id: type.id,
                name: type.data.name,
                categories: type.data.ecommerce.categories,
              });

              that.selectedOrderType = {
                id: type.id,
                name: type.data.name,
                categories: type.data.ecommerce.categories,
              };

              this.localStorageService.set('selectedOrderTypeId', this.selectedOrderType['id']);

              this.getAllowedProductsToUser();
            }
          });
        }
      });
  }

  getGrades() {
    this.apiService.getGrades('').subscribe((res) => {
      if (res['error']) {
        console.log('Erro ao recuperar as grades');
      } else {
        this.grades = res['grades'];
      }
    });
  }

  getAllowedProductsToUser() {
    const that = this;
    let is_search = false;
    this.paginatedData = [];

    if (this.term && this.term !== '') {
      is_search = true;
    }

    if (
      typeof this.cachedData[this.page] !== 'undefined' &&
      this.cachedData[this.page]['products'].length > 0 &&
      !is_search
    ) {
      const cachedData = this.cachedData[this.page];

      this.paginatedData = cachedData['products'];
      this.nbHits = cachedData['nbHits'];
      this.nbPages = cachedData['nbPages'];
      this.maxSize = cachedData['maxSize'];

      this.loadedData = true;
    } else {
      // this.firebase.functions().useFunctionsEmulator('http://localhost:5001');
      const productsRequest = this.firebase
        .functions()
        .httpsCallable('products-getCart');

      const data = {
        name: this.term ? this.term : '',
        price_table_id: this.user.store.default_price_table_code,
        page: this.page,
        source: 'new-ecommerce',
        company: {
          id: this.ecommerceConfigs['biaction_id'],
        },
        order_type_id: this.selectedOrderType['id'],
      };

      productsRequest(data)
        .then((res) => {
          const currentPage = res['data']['current_page'];
          const fullPage = res['data']['full_page'];

          if (res['data']['products']) {
            res['data']['products'].forEach((prod) => {
              const categoryId = prod.category_id;
              const findCategory = this.allCategories.find((cat) => String(categoryId) === String(cat.id));
              if(findCategory === undefined || (findCategory !== undefined && findCategory.allow_ecommerce === 1)) {

              prod['image'] = prod['photo_cover']
                ? 'https://p.biaction.com.br/' +
                this.ecommerceConfigs['pd_id'] +
                '/products/300x300/' +
                prod['photo_cover']
                : 'https://app.pedidosdigitais.com.br/img/sem-imagem-mini.png';
              prod['has_discount'] =
                prod['default_discount'] > 0 &&
                prod['price_no_discounts'] > prod['price_with_discounts']
                  ? true
                  : false;
              prod['price_default'] =
                prod['price_default'] && prod['price_default'] > 0
                  ? prod['price_default']
                  : 0;
              prod['price_no_discounts'] = prod['product_price_table'];
              prod['price_with_discounts'] = prod['product_price_table'] - (prod['product_price_table'] * prod['default_discount']) / 100;
              prod['total_discount_percentage'] = prod['has_discount']
                ? numeral(
                  ((prod['price_with_discounts'] -
                      prod['price_no_discounts']) /
                    prod['price_no_discounts']) *
                  100
                ).format('0')
                : 0;
              prod['stock'] =
                prod['stock_place_order_type'] > 0
                  ? prod['stock_place_order_type']
                  : 0;

              const findGrade = this.grades.find((grade) => {
                return grade.product_id.toString() === prod.id;
              });

              if (findGrade) {
                prod['grade_id'] = findGrade['id'];
              }

              if (typeof this.cachedData[this.page] === 'undefined') {
                this.cachedData[this.page] = {
                  nbHits: 44,
                  nbPages: fullPage,
                  maxSize: fullPage,
                  products: [],
                };
              }

              const ipi = prod.ipi;
              if (
                prod.ncm !== '' &&
                this.ncm_list !== undefined &&
                Object.keys(this.ncm_list).length > 0
              ) {
                const ncm = this.ncm_list[prod.ncm];
                if (ncm !== undefined) {
                  const state = this.user.store.address_state;
                  const ipi_ncm = ncm.ipi;
                  const isento_ipi = ncm.isento_ipi ? ncm.isento_ipi : false;
                  if (!isento_ipi) {
                    if (ipi_ncm && !isNaN(ipi_ncm)) {
                      prod['ipi'] = ipi_ncm;
                    } else {
                      prod['ipi'] = ipi !== -1 && ipi !== null ? ipi : 0;
                    }
                  } else {
                    prod['ipi'] = 0;
                  }
                  if (!this.isento_st) {
                    if (prod.fromabroad) {
                      prod['st'] =
                        ncm.im !== undefined && ncm.im[state] !== undefined
                          ? ncm.im[state]
                          : 0;
                    } else {
                      prod['st'] =
                        ncm.st !== undefined && ncm.st[state] !== undefined
                          ? ncm.st[state]
                          : 0;
                    }
                  } else {
                    prod['st'] = 0;
                  }
                  prod['tax_discount'] =
                    ncm.tax_discount !== undefined &&
                    ncm.tax_discount[state] !== undefined
                      ? ncm.tax_discount[state]
                      : 0;
                } else {
                  prod['ipi'] = ipi !== -1 && ipi !== null ? ipi : 0;
                  prod['st'] = 0;
                  prod['tax_discount'] = 0;
                }
              } else {
                prod['ipi'] = ipi !== -1 && ipi !== null ? ipi : 0;
                prod['st'] = 0;
                prod['tax_discount'] = 0;
              }

              this.paginatedData.push(prod);
              this.cachedData[this.page]['products'].push(prod);
              }
            });
          }

          this.nbHits = 1;
          this.nbPages = fullPage;
          this.maxSize = fullPage;
          this.page = currentPage;

          if (this.user.client.allow_popup && !is_search && this.page == 1) {
            this.openBannerPopup();
          }

          this.loadedData = true;

          if (this.term && this.term !== '') {
            setTimeout(function () {
              that.highlightFoundTerms();
            }, 1000);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  getAllProducts() {
    const that = this;
    this.paginatedData = [];
    this.loadedData = false;
    let is_search = false;

    if (this.term && this.term !== '') {
      is_search = true;
    }

    if (typeof this.cachedData[this.page] !== 'undefined' && this.cachedData[this.page]['products'].length > 0) {
      const cachedData = this.cachedData[this.page];

      this.paginatedData = cachedData['products'];
      this.nbHits = cachedData['nbHits'];
      this.nbPages = cachedData['nbPages'];
      this.maxSize = cachedData['maxSize'];

      this.loadedData = true;
    } else {
      // this.firebase.functions().useFunctionsEmulator('http://localhost:5001');
      const productsRequest = this.firebase.functions().httpsCallable('products-getCart');

      const store_access = this.ecommerceConfigs['store_access'] ? this.ecommerceConfigs['store_access'] : 2;
      const priceTableId = store_access === 1 ? this.loggedOutPriceTable ? this.loggedOutPriceTable :
        this.defaultPriceTable : '';

      const data = {
        name: this.term ? this.term : '',
        price_table_id: priceTableId,
        page: this.page,
        source: 'new-ecommerce',
        company: {
          id: this.ecommerceConfigs['biaction_id'],
        },
      };

      productsRequest(data)
        .then((res) => {
          const currentPage = res['data']['current_page'];
          const fullPage = res['data']['full_page'];

          if (res['data']['products']) {
            res['data']['products'].forEach((prod) => {
              prod['image'] = prod['photo_cover']
                ? 'https://p.biaction.com.br/' +
                this.ecommerceConfigs['pd_id'] +
                '/products/300x300/' +
                prod['photo_cover']
                : 'https://app.pedidosdigitais.com.br/img/sem-imagem-mini.png';

              prod['has_discount'] =
                prod['default_discount'] > 0 &&
                prod['price_no_discounts'] > prod['price_with_discounts']
                  ? true
                  : false;
              prod['price_default'] =
                prod['price_default'] && prod['price_default'] > 0
                  ? prod['price_default']
                  : 0;
              prod['price_no_discounts'] = prod['product_price_table'];
              prod['price_with_discounts'] = prod['product_price_table'] - (prod['product_price_table'] *
                prod['default_discount']) / 100;
              prod['total_discount_percentage'] = prod['has_discount']
                ? numeral(
                  ((prod['price_with_discounts'] -
                      prod['price_no_discounts']) /
                    prod['price_no_discounts']) *
                  100
                ).format('0')
                : 0;
              prod['stock'] = prod['stock'] > 0 ? prod['stock'] : 0;

              if (typeof this.cachedData[this.page] === 'undefined') {
                this.cachedData[this.page] = {
                  nbHits: 44,
                  nbPages: fullPage,
                  maxSize: fullPage,
                  products: [],
                };
              }

              const ipi = prod.ipi;
              if (
                prod.ncm !== '' &&
                this.ncm_list !== undefined &&
                Object.keys(this.ncm_list).length > 0
              ) {
                const ncm = this.ncm_list[prod.ncm];
                if (ncm !== undefined) {
                  const state = this.user.store.address_state;
                  const ipi_ncm = ncm.ipi;
                  const isento_ipi = ncm.isento_ipi ? ncm.isento_ipi : false;
                  if (!isento_ipi) {
                    if (ipi_ncm && !isNaN(ipi_ncm)) {
                      prod['ipi'] = ipi_ncm;
                    } else {
                      prod['ipi'] = ipi !== -1 && ipi !== null ? ipi : 0;
                    }
                  } else {
                    prod['ipi'] = 0;
                  }
                  if (!this.isento_st) {
                    if (prod.fromabroad) {
                      prod['st'] =
                        ncm.im !== undefined && ncm.im[state] !== undefined
                          ? ncm.im[state]
                          : 0;
                    } else {
                      prod['st'] =
                        ncm.st !== undefined && ncm.st[state] !== undefined
                          ? ncm.st[state]
                          : 0;
                    }
                  } else {
                    prod['st'] = 0;
                  }
                  prod['tax_discount'] =
                    ncm.tax_discount !== undefined &&
                    ncm.tax_discount[state] !== undefined
                      ? ncm.tax_discount[state]
                      : 0;
                } else {
                  prod['ipi'] = ipi !== -1 && ipi !== null ? ipi : 0;
                  prod['st'] = 0;
                  prod['tax_discount'] = 0;
                }
              } else {
                prod['ipi'] = ipi !== -1 && ipi !== null ? ipi : 0;
                prod['st'] = 0;
                prod['tax_discount'] = 0;
              }

              this.paginatedData.push(prod);
              this.cachedData[this.page]['products'].push(prod);
            });
          }

          this.nbHits = 1;
          this.nbPages = fullPage;
          this.maxSize = fullPage;
          this.page = currentPage;

          this.loadedData = true;

          if (this.page == 1) {
            this.openBannerPopup();
          }

          if (this.term && this.term !== '') {
            setTimeout(function () {
              that.highlightFoundTerms();
            }, 1000);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  onPageChange(page: number): void {
    this.page = page;

    if (this.isLoggedIn) {
      this.getAllowedProductsToUser();
    } else {
      this.getAllProducts();
    }

  }

  changeColor(event) {
    event.preventDefault();
    const target = event.currentTarget.id;

    if (target === 'pills-list-tab') {
      this.isGrid = false;
      $('#pills-grid')
        .removeClass('show active')
        .animate({overflow: 'hidden', opacity: '0.4'}, 1300, 'easeOutCubic');
      $('#pills-list')
        .addClass('show active')
        .animate({opacity: '1'}, 1000, 'easeOutCubic');

      if ($('#pills-list-tab svg path').hasClass('icon-active')) {
        $('#pills-list-tab svg path').removeClass('icon-active');
        $('#pills-list-tab svg path').addClass('icon-inactive');
      } else {
        $('#pills-list-tab svg path').addClass('icon-active');
        $('#pills-list-tab svg path').removeClass('icon-inactive');
        $('#pills-grid-tab svg path').removeClass('icon-active');
        $('#pills-grid-tab svg path').addClass('icon-inactive');
      }
    } else {
      event.preventDefault();
    }

    if (target === 'pills-grid-tab') {
      this.isGrid = true;
      $('#pills-list')
        .removeClass('show active')
        .animate({overflow: 'hidden', opacity: '0.4'}, 1300, 'easeOutCubic');
      $('#pills-grid')
        .addClass('show active')
        .animate({opacity: '1'}, 1000, 'easeOutCubic');

      if ($('#pills-grid-tab svg path').hasClass('icon-active')) {
        $('#pills-grid-tab svg path').removeClass('icon-active');
        $('#pills-grid-tab svg path').addClass('icon-inactive');
      } else {
        $('#pills-grid-tab svg path').addClass('icon-active');
        $('#pills-grid-tab svg path').removeClass('icon-inactive');
        $('#pills-list-tab svg path').removeClass('icon-active');
        $('#pills-list-tab svg path').addClass('icon-inactive');
      }
    } else {
      event.preventDefault();
    }
  }

  changeOrderType() {
    const orderType = $('#order-type').val();
    const find = this.orderTypes.find(
      (t) => String(t.id) === String(orderType)
    );

    if (find.length) {
      this.selectedOrderType = orderType;
    }
  }

  getStorageUrl(path) {
    path = path.replace(/^\/?(storage\/)?uploads\//, '');

    if (path.indexOf('/') === 0) {
      path = path.substr(1);
    }

    return `https://p.biaction.com.br/${this.ecommerceConfigs['pd_id']}/${path}`;
  }

  changeHitsPerPage() {
    const hitsPerPage = parseInt($('#hitsPerPage').val());
    this.hitsPerPage = hitsPerPage;

    this.cachedData = [];
    this.getAllowedProductsToUser();
  }

  openBannerPopup() {
    if (this.ecommerceConfigs['store_popup_image'] !== '' && this.ecommerceConfigs['show_banner']) {
      this.bannerImage = `https://p.biaction.com.br/${this.ecommerceConfigs['pd_id']}/${this.ecommerceConfigs['store_popup_image']}`;

      $('#banner-modal').modal('show');
    }
  }

  closeBannerModal() {
    this.localStorageService.set('show_banner', false);
  }

  trackByFn(item) {
    return item.id;
  }

  highlightFoundTerms() {
    const searchText = this.term;
    const regex = new RegExp(searchText, 'gi');

    $.each($('.product-content .product-description a'), (index, element) => {
      const text = $(element)
        .text()
        .replace(/(<mark class="highlight">|<\/mark>)/gim, '');
      const newText = text.replace(
        regex,
        '<mark class="highlight" style="background-color: #ff0">$&</mark>'
      );

      $(element).html(newText);
    });
  }

  openProductDetails(product) {
    /* Só navegará para Detalhes do Produto, se o item.grade_id
    for null e o produto não possuir nenhuma relação na tabela grade. */

    const minimum = product.minimum ? product.minimum : 0;
    const multiple = product.multiple ? product.multiple : 0;
    const maximum = product.maximum ? product.maximum : 0;
    let msg = '';

    let goToPreCart = false,
      qtd = 0;

    if (this.showQtdField) {
      let qtdField = $(`#${product.id}_qtd`).val();

      if (qtdField && qtdField !== '') {
        goToPreCart = true;
        qtd = qtdField;
      }

      if (minimum > 0 && qtdField > 0) {
        if (qtdField < minimum) {
          msg += 'A Quantidade mínima para esse produto é ' + minimum + '.<br>';
        }
      }

      if (maximum > 0 && qtdField > 0) {
        if (qtdField > maximum) {
          msg += 'A Quantidade máxima para esse produto é ' + maximum + '.<br>';
        }
      }

      if (multiple > 0 && qtdField > 0) {
        const rest =
          (parseFloat((100 * qtdField).toFixed(2)) %
            parseFloat((100 * multiple).toFixed(2))) /
          100;
        if (rest !== 0) {
          msg +=
            'A Quantidade deve ser um valor múltiplo de ' + multiple + '.<br>';
        }
      }
    }

    if (msg !== '') {
      this.toastr.showWarning(msg);
    } else {
      if (goToPreCart) {
        this.router.navigate([`/precart/${product.id}`], {
          queryParams: {qtd: qtd},
        });
      } else {
        if (product.grade === null) {
          this.router.navigate([`/products/${product.id}`], {
            queryParams: {
              page: this.page,
              orderTypeId: this.selectedOrderType,
            },
          });
        } else {
          if (
            product.grade &&
            product.grade.products &&
            product.grade.products.length > 0
          ) {
            this.currentGrade = product.grade;

            for (let i = 0; i < this.currentGrade.products.length; i++) {
              const prod = this.currentGrade.products[i];

              prod.variations = prod.variations
                ? JSON.parse(prod.variations)
                : [];

              prod.isInsideCart = false;
              prod.isInsideCart = this.isInsideCart(prod.id) ? true : false;

              if (prod.isInsideCart) {
                prod.quantity = this.cartService.getProductQtd(prod.id);
              }
            }

            $('#modal-product-grade').modal('show');
          } else {
            this.router.navigate([`/products/${product.id}`], {
              queryParams: {
                page: this.page,
                orderTypeId: this.selectedOrderType,
              },
            });
          }
        }
      }
    }
  }

  addToCart(product) {
    const qtd = $(`#add_cart_${product.id}`).val();
    this.cartService.setProduct(product, qtd, false);

    product.isInsideCart = true;
    this.toastrService.showSuccess('Produto inserido ao carrinho com sucesso!');
  }

  removeFromCart(product) {
    this.cartService.removeProduct(product.id);

    product.isInsideCart = false;
    this.toastrService.showSuccess('Produto removido do carrinho com sucesso!');
  }

  changeQtd(product) {
    const qtd = $(`#add_cart_${product.id}`).val();
    product.quantity = qtd;
  }

  isInsideCart(productId) {
    return this.cartService.isInsideCart(productId);
  }

  getProductCartQtd(product) {
    this.cartService.getProductQtd(product);
  }

  openInfos(product) {
    this.productSelected = product;
    $('#modal-products-infos').modal('show');
  }

  // função para pegar dados do componente filho
  filterProducts(res) {
    this.paginatedData = [];
    const that = this;

    const currentPage = res['data']['current_page'];
    const fullPage = res['data']['full_page'];

    if (res['data']['products']) {
      res['data']['products'].forEach((prod) => {
        const categoryId = prod.category_id;
        const findCategory = this.allCategories.find((cat) => String(categoryId) === String(cat.id));
        if(findCategory === undefined || (findCategory !== undefined && findCategory.allow_ecommerce === 1)) {

        prod['image'] = prod['photo_cover'] ?
          'https://p.biaction.com.br/' + this.ecommerceConfigs['pd_id'] + '/products/300x300/' + prod['photo_cover'] :
          'https://app.pedidosdigitais.com.br/img/sem-imagem-mini.png';
        prod['has_discount'] = prod['default_discount'] > 0 &&
          prod['price_no_discounts'] > prod['price_with_discounts'] ? true : false;
        prod['price_default'] = prod['price_default'] && prod['price_default'] > 0 ? prod['price_default'] : 0;
        prod['price_no_discounts'] = prod['product_price_table'];
        prod['price_with_discounts'] = prod['product_price_table'] -
          (prod['product_price_table'] * prod['default_discount']) / 100;
        prod['total_discount_percentage'] = prod['has_discount'] ?
          numeral(((prod['price_with_discounts'] - prod['price_no_discounts']) / prod['price_no_discounts']) * 100
          ).format('0') : 0;
        prod['stock'] = prod['stock_place_order_type'] > 0 ? prod['stock_place_order_type'] : 0;

        const findGrade = this.grades.find((grade) => {
          return grade.product_id.toString() === prod.id;
        });

        if (findGrade) {
          prod['grade_id'] = findGrade['id'];
        }

        if (typeof this.cachedData[this.page] === 'undefined') {
          this.cachedData[this.page] = {
            nbHits: 44,
            nbPages: fullPage,
            maxSize: fullPage,
            products: [],
          };
        }

        const ipi = prod.ipi;
        if (
          prod.ncm !== '' &&
          this.ncm_list !== undefined &&
          Object.keys(this.ncm_list).length > 0
        ) {
          const ncm = this.ncm_list[prod.ncm];
          if (ncm !== undefined) {
            const state = this.user.store.address_state;
            const ipi_ncm = ncm.ipi;
            const isento_ipi = ncm.isento_ipi ? ncm.isento_ipi : false;
            if (!isento_ipi) {
              if (ipi_ncm && !isNaN(ipi_ncm)) {
                prod['ipi'] = ipi_ncm;
              } else {
                prod['ipi'] = ipi !== -1 && ipi !== null ? ipi : 0;
              }
            } else {
              prod['ipi'] = 0;
            }
            if (!this.isento_st) {
              if (prod.fromabroad) {
                prod['st'] = ncm.im !== undefined && ncm.im[state] !== undefined ? ncm.im[state] : 0;
              } else {
                prod['st'] = ncm.st !== undefined && ncm.st[state] !== undefined ? ncm.st[state] : 0;
              }
            } else {
              prod['st'] = 0;
            }
            prod['tax_discount'] = ncm.tax_discount && ncm.tax_discount[state] ? ncm.tax_discount[state] : 0;
          } else {
            prod['ipi'] = ipi !== -1 && ipi !== null ? ipi : 0;
            prod['st'] = 0;
            prod['tax_discount'] = 0;
          }
        } else {
          prod['ipi'] = ipi !== -1 && ipi !== null ? ipi : 0;
          prod['st'] = 0;
          prod['tax_discount'] = 0;
        }

        this.paginatedData.push(prod);
        this.cachedData[this.page]['products'].push(prod);
         }
      });
    }

    this.nbHits = 1;
    this.nbPages = fullPage;
    this.maxSize = fullPage;
    this.page = currentPage;
  }
}
