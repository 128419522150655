import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api/api.service';
import { UserService } from '../../services/user/user.service';

import * as numeral from 'numeral';
import { Router } from '@angular/router';

declare var $: any;

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.css']
})
export class OrdersComponent implements OnInit {

  loadedData:boolean = false;
  pageOfItems = [];

  user;
  orders = [];
  order = {};

  paginatedData = [];
  page: number = 1;
  hitsPerPage: number = 20;
  nbHits: number;
  nbPages: number = 1;
  maxSize: number = 7;

  constructor(
    private apiService: ApiService,
    private userService: UserService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.getUser();
  }

  getUser() {
    this.userService.getUserData().then((res) => {
      if (!res['error']) {
        this.user = res['user'];
      }

      this.getUserOrders();
    });
  }

  getUserOrders() {
    this.paginatedData = [];
    this.loadedData = false;

    this.apiService.getUserOrdersPaginated({
      user: this.user,
      page: this.page,
      hitsPerPage: this.hitsPerPage,
    }).subscribe((res) => {

      if (!res['error']) {
        this.orders = res['orders'];

        res['orders'].forEach((order) => {
          this.paginatedData.push(order);
        });

        this.nbHits = res['nbHits'];
        this.nbPages = res['nbPages'];
        this.maxSize = res['maxSize'];
      }

      this.loadedData = true;
    });
  }

  orderDetails(order) {
    this.order = order;

    this.router.navigate([`/orders/${this.order['code']}`]);
  }

  onPageChange(page: number): void {
    this.page = page;
    this.getUserOrders();
  }

}
